.form-container {
  display: inline-flex;
  width: 100%;
  padding: 2em;
  position: relative;
}

.custom-button,
.save-button {
  border-radius: 0;
}

.float-right {
  float: right;
  margin-right: 6px;
}

.save-button {
  position: absolute;
  right: 40px;
  padding: 6px 24px;
}

.banner {
  width: 100%;
  height: 5em;
  background-image: linear-gradient(to right, #00aac1, #01bfa7);
}

.center {
  margin-top: 300px;
}

.div-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
