.editable-section {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editable-prop {
  height: 5rem;
  margin-right: 6px;
  justify-content: space-between;
  flex-direction: row;
  display: inline-flex;
}

.editable-prop-banner {
  background-color: #eaf2f5;
  align-items: center;
}

.section-margin {
  margin-top: 1rem;
}

.editable-prop-edit {
  background-color: #fbfbfb;
  height: 60vh;
}

.prop-buttons-section {
  justify-content: space-between;
  flex-direction: row;
  display: inline-flex;
  align-items: center;
  margin-right: 1rem;
}

.small-btns {
  width: 100px;
  margin-right: 5rem;
}

.icon-btn {
  display: inline-flex;
  flex-direction: row;
  width: 15rem;
  justify-content: space-between;
  margin-right: 15px;
  color: #83858c;
  font-weight: 500;
}

.icon {
  margin-left: 15px;
  width: 18px;
}

.prop-title {
  margin-left: 1rem;
  margin-top: inherit;
  color: #83858c;
  font-weight: 500;
}

.edit-input-group {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-end;
}

.edit-input-group-right {
  justify-content: start;
}

.edit-input {
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  width: 290px;
  border-radius: 0;
}

.edit-input-disabled {
  margin-left: 1rem;
  width: 290px;
  border-radius: 0;
}

.edit-col {
  margin-top: 3rem;
  margin-left: 2rem;
  width: 35%;
  flex: 0;
}

.edit-col-right {
  width: 100%;
  margin-top: 3rem;
  margin-left: 2rem;
}

.icon-section {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 9999;
  /*this will keep the icon appearing all time, even when on input::focus*/
}

.search-icon {
  height: 20px;
}

.input-group {
  width: 300px;
}

.breadcrumb {
  margin-left: 0.5rem;
  --bs-breadcrumb-margin-bottom: 0.25rem;
}

@import url('https://rsms.me/inter/inter.css');

/**
 * Main wrapper
 */
.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: 'Inter', sans-serif;

  width: 300px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: 'Inter var', sans-serif;
  }
}

.search-container {
  width: 300px;
  height: 300px;
  background: white;
  margin-left: 1rem !important;
  margin-top: 1rem;
  overflow: auto;
}

.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 3rem;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus
  .select-search-input,
.select-search-container:not(.select-search-is-disabled)
  .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding: 16px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}

.editable-settings-container {
  padding-bottom: 1rem;
}

.radios {
  padding-bottom: 1rem;
}

.badges-box {
  display: inline-flex;
}

.badge {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-items: center;
  align-items: baseline;
  margin-left: 1rem;
  background: #83858c !important;
  border-radius: 0.9rem;
  width: 4.5rem;
  height: 2rem;
}

.delete-badge {
  font-size: medium;
}
