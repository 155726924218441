.subscription,
.configuration {
  padding: 50px 0;
}

.account-header {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 20px;
}

.account-header h2 {
  font-size: 20px;
  font-weight: 400;
}

.subscription table,
.configuration table {
  table-layout: fixed;
}

.subscription table td {
  border: none;
}

.subscription-box {
  padding: 16px 20px;
  background: #fbfbfb;
}

.subscription-box dl {
  display: flex;
  gap: 10px;
}
