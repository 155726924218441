.tabs-section {
  width: 100%;
  position: relative;
}

.dropdown-basic {
  background-color: #fbfbfb;
}

.dropdown-menu {
  position: relative !important;
  transform: inherit !important;
}

.TabsSection {
  border-bottom: solid 1px #cbcbcb;
  gap: 50px;
}

.TabsSection.nav-fill .nav-item {
  flex: none;
}

.TabsSection .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #83858c;
  border: 0;
  padding: 10px 0;
}

.TabsSection .nav-link:hover {
  color: #606060;
  border: 0;
}

.TabsSection .nav-link.active {
  color: #3977ce;
  border: 0;
  border-bottom: solid 3px #3977ce;
}

.SubTabs {
  border: 0;
}

.SubTabs .nav-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #83858c;
  border: 0;
  padding: 10px 0;
}

.SubTabs .nav-link.active {
  color: #3977ce;
}
