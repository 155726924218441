.view {
  padding-top: 3rem;
  margin-right: 0;
  margin-left: 0;
}

.dropdown-toggle {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fbfbfb;
  color: #000000;
  border-radius: 0%;
  border-color: #fbfbfb;
  border-bottom-color: #e4e4e4;
  border-bottom-width: 1px;
}

.dropdown-toggle:active {
  background-color: #fbfbfb;
  color: #000000;
}

.dropdown-toggle::shadow {
  background-color: #fbfbfb;
  color: #000000;
}

.dropdown-toggle:hover {
  background-color: #fbfbfb;
  color: #000000;
}

.dropdown {
  width: 100%;
}

.col {
  width: 100%;
}

.dropdown-toggle::after {
  margin-left: auto;
}

.side-col {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}

.side-col p {
  margin: 0;
}

.side-title {
  font-size: 1.5em;
  font-weight: 500;
}

.side-text {
  font-size: 0.8em;
  font-weight: 300;
  margin-top: 20px;
}

.side-switch {
  margin-left: 2em;
  size: 4rem;
}

.form-switch .form-check-input {
  width: 50px;
  height: 25px;
}
