.ContactSwitcher {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-top: 50px;
}

.ContactSwitcher_group {
  flex: 50%;
}

.ContactSwitcher_group img {
  min-height: 45px;
  width: auto;
}

.ContactSwitcher_group select {
  border-radius: 40px;
}

.ContactSwitcher_group .ContactSwitcher_select + .ContactSwitcher_select {
  margin-top: 15px;
  position: relative;
}

.ContactSwitcher_select {
  position: relative;
}

.ContactSwitcher_select.withIcon::after {
  position: absolute;
  content: url('./../../../../../../public/static/images/switch-arrow.svg');
  top: 8px;
  right: -27px;
}
